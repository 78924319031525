import styled from '@emotion/styled'
import { theme } from 'app/theme'
import { rgba } from 'emotion-rgba'
import React, { memo } from 'react'

export interface Props {
  active?: boolean
  category: string
  label: string
  onClick?: (e: any) => void
}

export const Filter = memo(function Filter({
  active = false,
  category,
  label,
  onClick,
}: Props) {
  if (!label) {
    return null
  }

  return (
    <Container
      className={active ? 'active' : undefined}
      data-category={category}
      onClick={onClick}
    >
      <Icon className={`icon-${category}`} />
      <Label>{label}</Label>
    </Container>
  )
})

const Container = styled.div`
  width: 25%;
  max-width: 142px;
  background: ${({ theme }) => theme.colors.variants.neutralLight1};
  cursor: pointer;
  padding: 1.125rem;
  text-align: center;
  transition: 0.3s ease-out;
  position: relative;
  &:before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
  }
  &:after {
    content: '';
    width: 0;
    height: 0;
    border-left: 0.5625rem solid transparent;
    border-right: 0.5625rem solid transparent;
    border-top: 0.5625rem solid
      ${({ theme }) => theme.colors.variants.neutralDark1};
    opacity: 0;
    position: absolute;
    bottom: -0.5625rem;
    left: 50%;
    transform: translateX(-50%);
    transition: 0.3s ease-out;
  }
  &:hover {
    background: ${({ theme }) => theme.colors.variants.neutralDark4};
  }
  &.active {
    background: ${({ theme }) => theme.colors.variants.neutralDark1};
    &:after {
      opacity: 1;
    }
  }

  @media (max-width: 1023px) {
    width: auto;
    max-width: none;
    background: none;
    &:after {
      display: none;
    }
    &.active {
      label {
        color: ${({ theme }) => theme.colors.variants.neutralLight4};
      }
    }
    label {
      color: ${({ theme }) => theme.colors.variants.neutralDark1};
    }
  }

  @media (max-width: 767px) {
    padding: 0.75rem 1rem;
  }
`

const Icon = styled.div`
  width: 3rem;
  height: 3rem;
  background-color: ${rgba(theme.colors.variants.neutralLight4, 0.12)};
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 50%;
  margin: 0 auto 0.625rem;
  &.icon-resort {
    background-image: url('/bed.svg');
  }
  &.icon-dining {
    background-image: url('/restaurant.svg');
  }
  &.icon-sparelax {
    background-image: url('/wellness.svg');
  }
  &.icon-family {
    background-image: url('/bear.svg');
  }
  &.icon-exclusiveclub {
    background-image: url('/star.svg');
  }

  @media (max-width: 1023px) {
    display: none;
  }
`

const Label = styled.label`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 0.875rem;
  font-weight: 600;
  letter-spacing: 0.0262rem;
  line-height: 0.9375rem;
`
