import styled from '@emotion/styled'
import React, { memo } from 'react'
import {
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemState,
} from 'react-accessible-accordion'
import { Minus, Plus } from 'react-feather'

export interface Props {
  category?: string
  className?: string
  description?: string
  title?: string
}

export const Item = memo(function Item({
  category,
  className,
  description,
  title,
}: Props) {
  if (!title) {
    return null
  }

  return (
    <Container className={className} data-category={category}>
      <Head>
        <Title>
          <Button>
            <State>{({ expanded }) => (expanded ? <Minus /> : <Plus />)}</State>
          </Button>
          <Label>{title}</Label>
        </Title>
      </Head>
      {description ? (
        <Panel dangerouslySetInnerHTML={{ __html: description }} />
      ) : null}
    </Container>
  )
})

const Container = styled(AccordionItem)`
  display: none;
  margin-top: 1.875rem;
  &.visible {
    display: block;
  }
`

const Head = styled(AccordionItemHeading)`
  cursor: pointer;
`

const Title = styled(AccordionItemButton)`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.variants.neutralDark1};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.375rem;
  font-weight: 500;
  line-height: 1.875rem;
`

const Button = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.25rem;
  height: 2.25rem;
  background: ${({ theme }) => theme.colors.variants.neutralLight2};
  border-radius: 50%;
  margin-right: 0.75rem;
  svg {
    stroke: ${({ theme }) => theme.colors.variants.neutralDark1};
  }
`

const State = styled(AccordionItemState)``

const Label = styled.div`
  width: calc(100% - 3rem);
`

const Panel = styled(AccordionItemPanel)`
  color: ${({ theme }) => theme.colors.variants.neutralDark3};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1rem;
  line-height: 1.75rem;
  margin-top: 1.125rem;
`
