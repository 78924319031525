import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import styled from '@emotion/styled'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { Media, MediaContextProvider, mediaStyle } from 'app/theme/media'
import { compact, uniqBy } from 'lodash'
import React, { memo, useState } from 'react'
import { Accordion } from 'react-accessible-accordion'
import Slider from 'react-slick'

import { Filter } from './Filter'
import { Item, Props as ItemProps } from './Item'

export interface Props {
  description?: string
  items: ItemProps[]
  title?: string
}

export const Faq = memo(function Faq({ description, items, title }: Props) {
  if (!items) {
    return null
  }

  const settings = {
    arrows: false,
    dots: false,
    infinite: false,
    variableWidth: true,
  }

  const firstCategory = items
    .map((item) => item.category)[0]
    ?.toLocaleLowerCase()
  const [filteredCategory, setFilteredCategory] = useState(firstCategory)

  const filters = uniqBy(
    compact(
      items.map((item) => {
        // @ts-ignore
        const category = item.category
          .replace(/[^a-zA-Z]/g, '')
          .toLocaleLowerCase()

        const label = item.category

        return label && category
          ? {
              category,
              label,
            }
          : undefined
      }),
    ),
    'label',
  )

  return (
    <Container>
      <Wrapper>
        {title ? <Title>{title}</Title> : null}
        {description ? (
          <Description dangerouslySetInnerHTML={{ __html: description }} />
        ) : null}
        <style type="text/css">{mediaStyle}</style>
        <MediaContextProvider>
          <Media lessThan="ipadHorizontal">
            <Carousel {...settings}>
              {filters.map((item, index) => {
                const category = item.category
                  ? item.category.replace(/[^a-zA-Z]/g, '').toLocaleLowerCase()
                  : undefined

                return (
                  <Filter
                    active={filteredCategory === category}
                    key={index}
                    {...item}
                    onClick={(e) => {
                      setFilteredCategory(
                        e.target.getAttribute('data-category'),
                      )
                    }}
                  />
                )
              })}
            </Carousel>
          </Media>
          <Media greaterThanOrEqual="ipadHorizontal">
            <Filters dial={2} row wrap>
              {filters.map((item, index) => {
                return (
                  <Filter
                    active={filteredCategory === item.category}
                    category={item.category}
                    key={index}
                    label={item.label}
                    onClick={(e) => {
                      setFilteredCategory(
                        e.target.getAttribute('data-category'),
                      )
                    }}
                  />
                )
              })}
            </Filters>
          </Media>
        </MediaContextProvider>
        <Accordion allowZeroExpanded>
          {items.map((item, index) => {
            const category = item.category
              ? item.category.replace(/[^a-zA-Z]/g, '').toLocaleLowerCase()
              : undefined

            return (
              <Item
                key={index}
                className={
                  filteredCategory === category ? 'visible' : undefined
                }
                {...item}
              />
            )
          })}
        </Accordion>
      </Wrapper>
    </Container>
  )
})

const Container = styled.section`
  background: ${({ theme }) => theme.colors.variants.neutralLight3};
  padding: 9rem 10vw;

  @media (max-width: 1199px) {
    padding: 7.5rem 8.454vw 5.625rem;
  }
`

const Wrapper = styled.div`
  max-width: 808px;
  margin: auto;
`

const Title = styled.h1`
  color: ${({ theme }) => theme.colors.variants.neutralDark1};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 4.375rem;
  line-height: 5.25rem;
  margin-bottom: 0.75rem;
  text-align: center;

  @media (max-width: 1199px) {
    font-size: 2.5rem;
    line-height: 3rem;
  }
`

const Description = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark3};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1rem;
  line-height: 1.75rem;
  margin-bottom: 2.25rem;
  text-align: center;
`

const Filters = styled(FlexBox)`
  max-width: 710px;
  margin: 0 auto 4.8125rem;
`

const Carousel = styled(Slider)`
  margin: 3.75rem 0 60px;

  .slick-list {
    overflow: visible;
  }

  .slick-track {
    display: flex;
  }
`
